<template>
  <div class="split-content" data-test="split-content">
    <div
      :class="[
        'split-content__content',
        'grid__row',
        flip && sticky ? 'split-content__content--flip' : ''
      ]"
    >
      <div
        class="split-content__container grid__column grid__column--12 grid__column--breakpoint--7--5"
        :class="{ 'grid__column--breakpoint--7--offset--1': flip }"
      >
        <div
          :style="`--topOffset: ${sticky ? topOffset : 'auto'}`"
          :class="{
            'split-content__left': true,
            'split-content__left--sticky': sticky
          }"
        >
          <slot name="left" />
        </div>
      </div>
      <div
        class="split-content__container grid__column grid__column--12 grid__column--breakpoint--7--6"
        :class="{ 'grid__column--breakpoint--7--offset--1': !flip }"
      >
        <div class="split-content__right">
          <slot name="right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SplitContent',
  props: {
    sticky: { type: Boolean, default: true },
    topOffset: { type: String, default: 'auto' },
    flip: { type: Boolean, default: false },
    alignItems: {
      type: String,
      default: 'center',
      validator: (value: string) => {
        return [
          'flex-start',
          'flex-end',
          'center',
          'stretch',
          'baseline'
        ].includes(value)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint' as bp;

.split-content {
  &__content {
    @include bp.min-width(bp.$breakpoint--7) {
      align-items: v-bind(alignItems);
    }

    &--flip {
      flex-direction: row-reverse;
    }
  }

  &__container {
    width: 100%;
  }

  &__left--sticky {
    @include bp.min-width(bp.$breakpoint--7) {
      object-fit: cover;
      position: -webkit-sticky;
      position: sticky;
      top: var(--topOffset);
    }
  }
}
</style>
